<template>
    <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
        <div class="d-flex align-items-center">
            <div v-bind:class="[{ 'colorwheel' : isColor  }, { 'nocolorwheel' : !isColor  }, 'wheel d-flex align-items-center justify-content-center']" style=" "></div>
            <div class="d-flex flex-column">
                <h6 class="mb-0 text-dark text-sm">{{text}}</h6>
            </div>
        </div>
        <div class="d-flex align-items-center text-dark text-sm font-weight-bold">
            R {{price | currency}}
        </div>
    </li>
</template>
<script>
    export default {
        props: {
            text: {
                type: String,
                default: ''
            },
            price: {
                type: Number,
                default: 0
            },
            isColor: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
<style>
    .wheel {
        width: 15px;
        height: 15px;
    }
    .colorwheel {
        margin-right: 5px;
        width: 15px;
        height: 15px;
        background: radial-gradient(circle closest-side, white, black 90%), conic-gradient(red, #ff8000, yellow, #80ff00, lime, #00ff80, aqua, #0080ff, blue, #8000ff, fuchsia, #ff0080, red);
        background-blend-mode: screen;
        border-radius: 50%;
    }

    .nocolorwheel {
        margin-right: 5px;
        width: 15px;
        height: 15px;
        background: radial-gradient(circle closest-side, white, black 90%), conic-gradient(red, #ff8000, yellow, #80ff00, lime, #00ff80, aqua, #0080ff, blue, #8000ff, fuchsia, #ff0080, red);
        border-radius: 50%;
    }
</style>