<template>
    <main class="main-content  mt-0">
        <div class="page-header align-items-start min-vh-100">
            <span class="mask bg-gradient-dark opacity-6"></span>
            <div class="container my-auto">
                <div class="row">

                    <div class="col-lg-4 col-md-8 col-12 mx-auto  child-view-alt h-100" v-if="mode !== 'transactions'">
                        <div class="card ">
                            <form v-on:submit.prevent="onSubmit">
                                <div class="card-body">
                                    <h4 class="font-weight-bolder text-center mt-2 mb-4" v-show="mode == 'sign-in'">Sign in</h4>
                                    <h4 class="font-weight-bolder text-center mt-2 mb-4" v-show="mode == 'reset-pin'">Reset Printing PIN</h4>
                                    <label class="form-label">Student Number</label>
                                    <div class="input-group input-group-outline mb-3">
                                        <input v-model="studentNumber" class="form-control">
                                    </div>
                                    <label class="form-label" v-show="mode == 'sign-in'">Printing PIN</label>
                                    <div class="font-weight-light" style="font-size: smaller;" v-show="mode == 'sign-in'">5-digit Printing PIN</div>
                                    <div class="input-group input-group-outline mb-0 justify-content-center" v-show="mode == 'sign-in'">
                                        <!--<input type="tel" inputmode="numeric" v-model="pin" class="form-control" maxlength="5" minlength="5">-->
                                        <pincode-input v-model="pin" :length="5" secure></pincode-input>
                                    </div>

                                    <div class="text-center">
                                        <custom-primary-button class="w-100  my-4 mb-2"
                                                               type="submit"
                                                               v-show="mode == 'sign-in'"
                                                               v-on:click="signIn"
                                                               :loading="loading">Sign In</custom-primary-button>
                                        <custom-primary-button class="w-100  my-4 mb-2"
                                                               type="submit"
                                                               v-show="mode == 'reset-pin'"
                                                               v-on:click="resetPIN"
                                                               :loading="loading">Reset PIN</custom-primary-button>
                                    </div>
                                    <p class="mt-4 text-sm text-center" v-show="mode == 'sign-in'">
                                        Forgot your Printing PIN?
                                        <a class="text-primary text-gradient font-weight-bold" style="cursor:pointer" v-on:click.stop="mode='reset-pin'">Reset PIN</a>
                                        <br />
                                        <small>Incase you have not yet registered, visit your stadiobooks.co.za portal to register for printing.</small>
                                    </p>
                                    <p class="mt-4 text-sm text-center" v-show="mode == 'reset-pin'">
                                        Want to Sign In?
                                        <a class="text-primary text-gradient font-weight-bold" style="cursor:pointer" v-on:click.stop="mode='sign-in'">Sign In</a>
                                    </p>
                                    <div class="mt-3 mb-2">
                                        <div style="position: absolute;left: calc(50% - 25px); background-color: white; margin-top: -12px; width: 50px; height: 25px; z-index: 9;" class="text-center ">OR</div>
                                        <hr />
                                    </div>
                                    <p class="mt-4 text-sm text-center">
                                        <a class="text-primary text-gradient font-weight-bold" style="cursor:pointer" v-on:click.stop="mode='transactions'">View Print Pricing</a>
                                    </p>
                                </div>
                            </form>


                        </div>
                    </div>
                    <div class="col-lg-4 col-md-8 col-12 mx-auto h-100 child-view-alt" v-if="mode === 'transactions'">
                        <!-- #region Pricing -->
                        <div class="card">
                            <div class="card-header pb-0 px-3">
                                <div class="row ">
                                    <div class="col-auto align-content-center">
                                        <a class="text-primary text-gradient font-weight-bold" style="cursor:pointer" v-on:click.stop="mode='sign-in'">Back to Sign in</a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body p-3 pb-0">
                                <div class="card-body pt-4 p-3">
                                    <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">Black and White</h6>
                                    <ul class="list-group">
                                        <printing-price-list-item text="Single Sided A4" :price="pricing.bwSingleSidedA4"></printing-price-list-item>
                                        <printing-price-list-item text="Double Sided A4" :price="pricing.bwDoubleSidedA4"></printing-price-list-item>
                                        <printing-price-list-item text="Single Sided A3" :price="pricing.bwSingleSidedA3"></printing-price-list-item>
                                        <printing-price-list-item text="Double Sided A3" :price="pricing.bwDoubleSidedA3"></printing-price-list-item>
                                    </ul>
                                    <h6 class="text-uppercase text-body text-xs font-weight-bolder my-3">Colour</h6>
                                    <ul class="list-group">
                                        <printing-price-list-item text="Single Sided A4" :price="pricing.colourSingleSidedA4" is-color></printing-price-list-item>
                                        <printing-price-list-item text="Double Sided A4" :price="pricing.colourDoubleSidedA4" is-color></printing-price-list-item>
                                        <printing-price-list-item text="Single Sided A3" :price="pricing.colourSingleSidedA3" is-color></printing-price-list-item>
                                        <printing-price-list-item text="Double Sided A3" :price="pricing.colourDoubleSidedA3" is-color></printing-price-list-item>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- #endregion -->
                    </div>
                </div>
            </div>
        </div>
        <custom-toast v-model="resetToast.show">
            {{resetToast.message}}
        </custom-toast>
    </main>
</template>

<script>
    import PincodeInput from 'vue-pincode-input';
    import { mapActions } from 'vuex';
    import CustomToast from '@/components/custom-toast.vue';
    import CustomPrimaryButton from '@/components/custom-primary-button.vue';
    import PrintingPriceListItem from '@/components/printing-price-list-item';
    export default {
        components: {
            CustomToast,
            CustomPrimaryButton,
            PincodeInput,
            PrintingPriceListItem
        },
        data() {
            return {
                transactions: {},
                studentNumber: '',
                pin: "",
                mode: 'sign-in',
                loading: false,
                resetToast: {
                    message: 'PIN Reset, Please check your mail.',
                    show: false
                }
            }
        },
        methods: {
            ...mapActions(['setAuth']),
            notification: function (message) {
                this.resetToast.message = message;
                this.resetToast.show = true;
            },
            resetPIN: async function () {
                if (this.loading) {
                    //this.notification('Already busy')
                    return;
                }
                this.loading = true;

                let result = await this.$http.post('/api/Auth/resetPIN', { studentNumber: this.studentNumber })
                if (result.status == 200) {
                    this.mode = 'sign-in'
                    this.notification('Reset done, check your email.')
                }
                else {
                    this.notification(result.data)
                }

                this.loading = false;
            },
            signIn: async function () {
                if (this.loading) {
                    return;
                }
                this.loading = true;

                let result = await this.$http.post('/api/Auth/Login', { studentNumber: this.studentNumber, pin: this.pin })
                if (result.status == 200) {
                    this.setAuth(result.data)
                    this.$router.push({ name: 'home' })
                }
                else {
                    this.notification(result.data)
                }

                this.loading = false;
            },
            onSubmit: async function () {
                if (this.mode == 'sign-in') {
                    await this.signIn();
                }
                else {
                    await this.resetPIN();
                }
            },
            getPricing: async function () {
                this.loading = true;
                let result = await this.$http.get('/api/Pricing/GetPricing')
                if (result.status == 200) {
                    this.pricing = result.data
                }

                this.loading = false;
            },
        },
        mounted: function () {
            this.getPricing();
        }
    }
</script>
